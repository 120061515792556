import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  ABOUT US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>
                        For over a decade, our family spent our summers growing
                        and working together on the farms of Zacatecas, Mexico.
                        As the two oldest siblings, our only option was to get
                        up each day at sunrise and do the manual labor of
                        milking cows, harvesting crops, and tending the
                        livestock until sundown. But no matter how long the day
                        was, our family always found the time to share a meal
                        around the dinner table with each other.
                      </p>
                      <p>
                        That table was filled with the fruits of our labor:
                        fresh ingredients picked, harvested, and butchered
                        directly from the farm onto our plates. Even now, the
                        memory of bringing Mom and Dad the meat and vegetables
                        to prepare is fresh in our minds. Over time, our work
                        went beyond just bringing the ingredients, but working
                        by their side in the kitchen to prepare the meals we
                        loved with fresh, authentic Mexican flavors.
                      </p>
                      <p>
                        Years later, in the 1980s, our family made the decision
                        to chase the opportunity of a better life and moved to
                        the United States in hopes of giving the family more
                        work opportunities and a better living experience than
                        the farms in Mexico. We spent the next two decades
                        moving from state to state and, job to job until we
                        found our new home and ended up settling in the small
                        town of Ionia, Michigan. But to truly feel at home, we
                        knew we had to bring our culture — the pride we put into
                        our food — with us.
                      </p>
                      <p>
                        Founding El Mariachi Mexican Grill was the final step in
                        making Ionia our home. Here, we have been accepted by
                        the community and given the opportunity to share our
                        culture, atmosphere, and food with you for the 12th year
                        running! We are proud to offer authentic Mexican
                        flavors, made fresh daily to be shared with the ones
                        close to you no matter the occasion. Have a seat at our
                        table — we hope our food can make you feel at home, too.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
